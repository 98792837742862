import React from 'react'
import { BottomForm as Styled } from './styled'
import Form from './Form'
import TopBottom from '@/components/TopBottom'

import Logo from "@/assets/LogoColor.svg"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'


const BottomForm = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "home"}, section: {eq: "bottom"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)

  const image = getImage(query.allDataJson.nodes[0].images[0])
  return (
    <Styled>
      <GatsbyImage image={image} alt="Proyecto Grupo Beltran"
        style={{
          position: 'absolute',
          objectFit: "cover",
          zIndex: -1,
          width: "100%",
          height: "100%"
        }}
      />

      <div className='quote'>
        <img src={Logo} alt="logo"
          width="70"
          height="50"
        />
        <h3>
          «La arquitectura es el punto de partida del que quiera llevar
          a la humanidad hacia un porvenir mejor.»
        </h3>
        <p>Alejándro Torres</p>

      </div>
      <Form />
      <TopBottom />
    </Styled>
  )
}

export default BottomForm