import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Tarjetas as Styled, Card } from './styled'

import Modal from "@/components/Modal"
import Separator from '@/components/styled/Separator'

import Tarjetas from '@/components/Tarjetas'
import { graphql, useStaticQuery } from 'gatsby'

const Galeria = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null)
  
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "home"}, section: {eq: "galeria"}}) {
        nodes {
          ...DefaultImage
          title
          text
        }
      }
    }
  `)
  const galeria = query.allDataJson.nodes
  return (
    <Styled>
      <Separator
        width="0"
        height="0"
        x="0"
        y="0"
        mobile={{
          directionX: "right",
          opacity: "0.8",
          width: "65%",
          height: "min(9vw, 2rem)",
          x: "1rem",
          y: "max(-4.5vw, -1rem)"
        }}
      />
      <h2>Proyectos en <br /><span>La Costa Atlántica</span></h2>      
      <Tarjetas>
        {

          galeria.map((value, key) => {
            const { title, text, images } = value
            const coverImage = getImage(images[0])
            return (
              <Card
                key={key}
                onClick={() =>{
                  setSelectedItems(images)
                  setIsOpen(true)
                }}>
                <GatsbyImage image={coverImage} alt="" style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  zIndex: "-100",
                  sizes: "(max-width: 768px) 60vw, 33vw"
                }} 
                />
                <div className='content'>
                  <h3>{title}</h3>
                  <p>
                    {text}
                  </p>
                </div>
              </Card>
            )
          }
          )
        }

      </Tarjetas>
      <Modal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        selectedItems={selectedItems}
      />
    </Styled>
  )
}

export default Galeria