import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import Separator from "@/components/styled/Separator"
import ContentRightImg from '@/components/ContentRightImg'
import { Content } from './styled'

import Logo from "@/assets/Logo.svg"



const QuienesSomos = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "home"}, section: {eq: "quienes_somos"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)

  const image = query.allDataJson.nodes[0].images[0]

  return (
    <ContentRightImg image={image}>
      <Separator
      
        width="85px"
        height="256px"
        x="min(6.8vw, 100px)"
        y="-80px"
        directionX="right"
        opacity= "0.8"

        mobile={{
          directionX: "right",
          opacity: "0.8",
          width: "63px",
          height: "222px",
          x: "1.5rem",
          y: "-120px"
        }}
      />
      <Content>
        <img 
          src={Logo}
          className="LogoMobile"
          alt="logo"
          width="150"
          height="80"
        />
        <h2>30 años <br /> <span>en el Mercado</span></h2>
        <div>
          <Separator
            width="clamp(50px, 4vw, 59px )"
            height="100%"
            x="0"
            y="0"
            mobile={{
              // opacity: "0.8",
              width: "0",
              height: "0",
              x: "0",
              y: "50%"
            }}
          />
          <h3>Somos Grupo Beltrán, una empresa constructora familiar de dos generaciones</h3>
          <p>
            El tiempo nos dio experiencia y nos convirtió en una empresa sólida. Contamos con un equipo de profesionales enfocados en crear experiencias para las familias, comprometidos con el cumplimiento de los tiempos, la eficacia y creatividad.
          </p>
          <div >
            <Separator

              width="0"
              height="0"
              x="0"
              y="0"

              mobile={{
                width: "2rem",
                height: "200px",
                x: "-2rem",
                y: "0"
              }}
            />
            <img src={Logo} alt="Logo"
              width="170"
              height="60"
            />
            <Link to="/nosotros">VER +</Link>
          </div>

        </div>
      </Content>
    </ContentRightImg>
  )
}

export default QuienesSomos