import React from 'react'
import { graphql } from 'gatsby'

import Seo from '@/components/seo'

import Layout from '@/components/layout'
import Carrousel from "@/components/carrousel"
import QuienesSomos from '../templates/home/QuienesSomos'
import Proyectos from '../templates/home/Proyectos'
import Galeria from '../templates/home/Galeria'
import Servicios from '../templates/home/Servicios'
import BottomForm from '../templates/home/BottomForm'

import LDJson from '@/components/LDJson'

const HomeBody = ({data}) => {

  
  const images = data.allDataJson.nodes[0].images

  return (
    <Layout>
      <Carrousel gallery={images} text="“Creamos experiencias para las familias”" />
      <QuienesSomos />
      <Proyectos />
      <Galeria />
      <Servicios />
      <BottomForm />
    </Layout>
  )
}
export const Head = () => (
  <Seo>
    <LDJson />
  </Seo>
)

export const query = graphql`
  query MyQuery {
    allDataJson(filter: {page: {eq: "home"}, section: {eq: "carrousel"}}) {
      nodes {
        images {
          childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [480, 768, 1080, 1366, 1920]
                width: 1920
                placeholder: NONE
            )
          }
        }
      }
    }
  }

`


export default HomeBody