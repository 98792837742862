import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Proyectos as Styled } from './styled'
import Separator from '@/components/styled/Separator'




const Proyectos = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "home"}, section: {eq: "proyectos"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const proyectos = query.allDataJson.nodes[0]
  const image = getImage(proyectos.images[0])

  return (
    <Styled>
      <Separator
        width="min(540px, 40%)"
        height="3rem"
        x="0"
        y="-1.5rem"
        opacity="0.8"
        mobile={{
          opacity: "0.8",
          width: "max(60%, 300px)",
          height: "3rem",
          x: "0",
          y: "-1.5rem"
        }}
      />
      <GatsbyImage
        image={image}
        alt="Obra: Marítima, Costa Esmeralda - Pinamar"
        sizes='2x'
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%"
        }}
      />
      <Link to="/proyectos">PROYECTOS</Link>
    </Styled>
  )
}

export default Proyectos