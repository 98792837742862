import React from 'react'

const LDJson = () => {
  return (
    <script type="application/ld+json">
      {`{
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "Place",
      "@id": "https://grupobeltranconstructora.com.ar/#place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Pinamar",
        "addressRegion": "Buenos Aires",
        "addressCountry": "Argentina"
      }
    },
    {
      "@type": [
        "HomeAndConstructionBusiness",
        "Organization",
        "GeneralContractor"
      ],
      "@id": "https://grupobeltranconstructora.com.ar/#organization",
      "name": "Grupo Beltran - Constructora",
      "url": "https://grupobeltranconstructora.com.ar",
      "telephone": "+54-2267-66-8509",

      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Pinamar",
        "addressRegion": "Buenos Aires",
        "postalCode": "7167",
        "addressCountry": "Argentina"
      },
      "logo": {
        "@type": "ImageObject",
        "@id": "https://grupobeltranconstructora.com.ar/#logo",
        "url": "https://grupo-beltran.mo.cloudinary.net/media/home/01-slider%20principal/slider03.jpg?tx=c_scale,w_auto",
        "caption": "Grupo Beltran - Constructora",
        "inLanguage": "es"
      },
      "openingHours": [
        "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday 09:00-18:00"
      ],
      "location": {
        "@id": "https://grupobeltranconstructora.com.ar/#place"
      },
      "image": {
        "@id": "https://grupobeltranconstructora.com.ar/#logo"
      }
    },
    {
      "@type": "WebSite",
      "@id": "https://grupobeltranconstructora.com.ar/#website",
      "url": "https://grupobeltranconstructora.com.ar",
      "name": "Grupo Beltran - Constructora",
      "publisher": {
        "@id": "https://grupobeltranconstructora.com.ar/#organization"
      },
      "inLanguage": "es"
    },
    {
      "@type": "ImageObject",
      "@id": "https://grupo-beltran.mo.cloudinary.net/media/home/01-slider%20principal/slider03.jpg?tx=c_scale,w_auto",
      "url": "https://grupo-beltran.mo.cloudinary.net/media/home/01-slider%20principal/slider03.jpg?tx=c_scale,w_auto",
      "inLanguage": "es"
    },
    {
      "@type": "WebPage",
      "@id": "https://grupobeltranconstructora.com.ar/#webpage",
      "url": "https://grupobeltranconstructora.com.ar/",
      "name": "Grupo Beltran - Pinamar",
      "datePublished": "2022-07-27",
      "dateModified": "2022-07-31",
      "about": {
        "@id": "https://grupobeltranconstructora.com.ar/#organization"
      },
      "isPartOf": {
        "@id": "https://grupobeltranconstructora.com.ar/#website"
      },
      "primaryImageOfPage": {
        "@id": "https://grupo-beltran.mo.cloudinary.net/media/home/01-slider%20principal/slider03.jpg?tx=c_scale,w_auto"
      },
      "inLanguage": "es"
    },
    {
      "@type": "Person",
      "@id": "https://grupobeltranconstructora.com.ar/#author",
      "name": "Grupo Beltran",
      "image": {
        "@type": "ImageObject",
        "@id": "https://grupobeltranconstructora.com.ar/LogoColor.svg",
        "url": "https://grupobeltranconstructora.com.ar/LogoColor.svg",
        "caption": "Grupo Beltran",
        "inLanguage": "es"
      },
      "sameAs": [
        "https://www.facebook.com/gbconstuctora",
        "https://www.instagram.com/gb.constructora/"
      ],

      "worksFor": {
        "@id": "https://grupobeltranconstructora.com.ar/#organization"
      }
    },
    {
      "@type": "Article",
      "headline": "Grupo Beltran - Constructora",
      "keywords": "Diseño, Construcción, Proyectos, Pinamar",
      "author": {
        "@id": "https://grupobeltranconstructora.com.ar/#author"
      },
      "publisher": {
        "@id": "https://grupobeltranconstructora.com.ar/#organization"
      },
      "description": "Somos una empresa constructora en Pinamar, con un equipo de profesionales enfocados en crear experiencias únicas",
      "name": "Grupo Beltran - Constructora",
      "@id": "https://grupobeltranconstructora.com.ar/#richSnippet",
      "isPartOf": {
        "@id": "https://grupobeltranconstructora.com.ar/#webpage"
      },
      "image": {
        "@id": "https://grupo-beltran.mo.cloudinary.net/media/home/01-slider%20principal/slider03.jpg?tx=c_scale,w_auto"
      },
      "inLanguage": "es",
      "mainEntityOfPage": {
        "@id": "https://grupobeltranconstructora.com.ar/#webpage"
      }
    }
  ]
}
`}
    </script>
  )
}

export default LDJson